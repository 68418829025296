<template>
  <div
    class="container"
    @click="showMap"
  >
    <span style="font-size: 1.3rem">{{ title }}</span>
    <span style="opacity: 0.7">{{ subTitle }}</span>
  </div>

</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps(['title', 'subTitle', 'link']);

const showMap = () => {
  window.open(props.link, '_blank');
};
</script>

<style lang="css" scoped>

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background-color: rgba(255, 227, 223, 0.5);
  border-radius: 15px;
  backdrop-filter: blur(2px);
  border: 1px solid rgba(1, 35, 69, 0.2);
}

.container {
  border: 2px solid rgba(222, 178, 248, 0.7);
  background: linear-gradient(to right, rgba(244, 248, 228, 0.5), rgba(213, 225, 255, 0.2), rgba(255, 249, 217, 0.5));
  background-size: 200% 100%;
  box-shadow: 5px 5px 15px rgba(255, 255, 255, 0.9);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.container:hover {
  background-position: 100% 100%;
  box-shadow: 5px 5px 15px rgba(255, 223, 165, 0.2);
}
</style>
